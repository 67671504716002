import React from 'react';
import R from '../Assets/R.png';
import E from '../Assets/E.png';
import V from '../Assets/V.png';
import I from '../Assets/I.png';
import W from '../Assets/W.png';
import T from '../Assets/T.png';
import H from '../Assets/H.png';
import Y from '../Assets/Y.png';
import A from '../Assets/A.png';

import '../Styles/Logo.css';

const Space = () => <div className="logo-space" />; // Adjust the class and style based on your needs

const Logo = () => {
  return (
    <div className="logo">
      <img src={R} alt="R" className="logo-letter" />
      <img src={E} alt="E" className="logo-letter" />
      <img src={V} alt="V" className="logo-letter" />
      <img src={I} alt="I" className="logo-letter" />
      <img src={E} alt="E" className="logo-letter" />
      <img src={W} alt="W" className="logo-letter" />
      <Space />
      <img src={T} alt="T" className="logo-letter" />
      <img src={H} alt="H" className="logo-letter" />
      <img src={E} alt="E" className="logo-letter" />
      <Space />
      <img src={Y} alt="Y" className="logo-letter" />
      <img src={E} alt="E" className="logo-letter" />
      <img src={A} alt="A" className="logo-letter" />
      <img src={R} alt="R" className="logo-letter" />
    </div>
  );
};

export default Logo;










// import React from 'react';

// const Logo = () => {
//   return (
//     <div className='logo' style={{ width: 'auto', position: 'fixed', top: '10%', left: '50%', transform: 'translate(-50%, -50%)' }}>
//       <svg version="1.1" id="Logo" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
//         width="70vw" height="182px" viewBox="0 0 1035 182" style={{ enableBackground: 'new 0 0 1035 182' }} xmlSpace="preserve">
//         <style>
//           {`
//             .st0{fill:#FFFFFF;stroke:#ddd;stroke-width:2;}
//             .st1{fill:#FFFFFF;stroke:#63CBB7;stroke-width:2;}
//             .st2{fill:#FFFFFF;stroke:#FC354F;stroke-width:2;}
//             #Bol1{stroke-dasharray: 2250;stroke-dashoffset: 0;-webkit-animation: anim_1  5s ease-in forwards;}
//             #Bol2{stroke-dasharray: 2250;stroke-dashoffset: 0;-webkit-animation: anim_2 5s ease-in forwards;}
//             #A1,#T1,#H1,#L1,#E1,#T2,#i1,#C1,#P1,#i2,#x1,#e2,#L2,#s1{stroke-dasharray: 2250;stroke-dashoffset: 0;-webkit-animation: anim_l 3s ease-in forwards;}
//             @-webkit-keyframes anim_l {from {stroke-dashoffset: 2250;fill:#FFFFFF;stroke:#ddd;stroke-width:3;}to {stroke-dashoffset: 0;fill:#333;stroke:#none;stroke-width:0;transition: 1s;}}
//             @-webkit-keyframes anim_1 {from {stroke-dashoffset: 2250;fill:#FFFFFF;stroke:#63CBB7;stroke-width:3;}to {stroke-dashoffset: 0;fill:#63CBB7;stroke:#none;stroke-width:0;transition: 1s;}}
//             @-webkit-keyframes anim_2 {from {stroke-dashoffset: 2250;fill:#FFFFFF;stroke:#FC354F;stroke-width:3;}to {stroke-dashoffset: 0;fill:#FC354F;stroke:#none;stroke-width:0;transition: 1s;}}
//           `}
//         </style>
//         <g id="Letters">
//           {/* Your SVG Paths */}
//           <path id="A1" className="st0" d="M82.9,48.8l28.2,79.1l-18.8,1l-6.8-17.6l-25,1.3l-4.8,18.2l-18.8,1l19.5-81.7L82.9,48.8z M64,95.7
// 		l16.3-0.9L70.7,67L64,95.7z"/>
//           <path id="T1" className="st0" d="M103.9,65.9l0.5-17.4l66.4,1.8l-0.5,17.4l-23.8-0.7l-1.7,63.1l-18.7-0.5l1.7-63.1L103.9,65.9z" />
//           <path id="H1" className="st0" d="M226.7,77.8L226,49.1l18.8-0.5l2,80.5l-18.8,0.5l-0.8-33.9l-27.8,0.7l0.8,33.9l-18.7,0.4l-2-80.5
// 		l18.7-0.5l0.7,28.7L226.7,77.8z"/>
//           <path id="L1" className="st0" d="M304,110.8l1,17.9l-42.6,2.4l-4.5-80.4l18.8-1l3.5,62.5L304,110.8z" />
//           <path id="E1" className="st0" d="M367,81.4l-0.7,15.9L335.4,96l-0.7,15.7l39.2,1.6l-0.8,17.9l-57.9-2.4l3.4-80.5l57.9,2.4l-0.8,17.9
// 		l-39.2-1.7l-0.5,13.2L367,81.4z"/>
//           <path id="T2" className="st0" d="M384.8,68.2l-0.7-17.3l66.4-2.8l0.7,17.4l-23.8,1l2.7,63.1l-18.7,0.8l-2.7-63.1L384.8,68.2z" />
//           <path id="Bol1" className="st1" d="M466.4,63.1c4,0.6,8.1,0.7,12.2,0.4c0.5,0,1-0.3,1.3-0.8c0.8-1.5,2.4-5.4,2.1-12.9
// 		c0-0.7-0.5-1.3-1.1-1.5c-5-1.4-10.2-1.6-15.3-0.5c-0.6,0.1-1.1,0.6-1.2,1.2c-0.9,4.3-0.6,8.8,0.9,13
// 		C465.3,62.6,465.8,63,466.4,63.1z"/>
//           <path id="i1" className="st0" d="M480.3,130.3l-18.8-0.7l2.3-59.9l18.8,0.7L480.3,130.3z" />
//           <path id="C1" className="st0" d="M556.8,53.7L550.8,70c-4.4-3.1-12-5-17.4-4.5c-13.6,1.3-20.5,12.3-19.2,25.4s10,22.6,23.8,21.2
// 		c5.4-0.5,12.5-3.9,16.2-7.9l9,15c-5.6,6.2-17,10.2-25,11c-23.8,2.3-40.8-14.3-43-37.5s11.2-42.9,35-45.2
// 		C538.1,46.8,550.1,48.6,556.8,53.7z"/>
//           <path id="P1" className="st0" d="M643.2,105.3l-17.2-0.8l-1.2,24.8l-18.7-0.9L610,48l35.9,1.8c15.1,0.7,24.7,14.8,24,28.6
// 		C669.2,92.7,657.7,106,643.2,105.3z M643,67.5l-15.1-0.7l-1,19.8l15.1,0.7c5.1,0.3,9.6-3.5,9.9-8.6c0-0.2,0-0.3,0-0.5
// 		C652.2,72.7,648.2,67.8,643,67.5z"/>
//           <path id="Bol2" className="st2" d="M684.6,63.7c4.1,0.3,8.1,0.1,12.2-0.6c0.5-0.1,1-0.4,1.2-0.9c0.7-1.6,1.9-5.5,1-13
// 		c-0.1-0.7-0.6-1.2-1.3-1.4c-5.1-1-10.3-0.8-15.3,0.8c-0.6,0.2-1,0.7-1.1,1.3c-0.6,4.4,0.1,8.8,1.9,12.9
// 		C683.4,63.3,684,63.6,684.6,63.7z"/>
//           <path id="i2" className="st0" d="M703.1,129.5l-18.8,0.9l-2.7-59.9l18.8-0.8L703.1,129.5z" />
//           <path id="x1" className="st0" d="M787.4,51.2l-24,37.3l22.5,43.2l-18.9-0.9l-17.2-28.9L730,129l-18.9-0.9l26.3-40.9l-20.3-39.4
// 		l18.9,0.9l14.9,27.5l17.7-25.9L787.4,51.2z"/>
//           <path id="e2" className="st0" d="M848.4,79.1l1,15.9l-30.9,2.1l1,15.6l39.1-2.6l1.2,17.9l-57.8,3.9l-5.3-80.3l57.8-3.8l1.2,17.9
// 		l-39.1,2.6l0.9,13.1L848.4,79.1z"/>
//           <path id="L2" className="st0" d="M915.5,110.8l1,17.9l-42.6,2.4l-4.5-80.4l18.8-1.1l3.5,62.5L915.5,110.8z" />
//           <path id="s1" className="st0" d="M953.5,131.1c-8.3-0.6-21.4-4.1-28.3-10.2l8.5-16.2c3.9,3,15.3,8.4,21.2,8.8c4.5,0.3,11.2-1.9,11.5-7
// 		c0.8-11.7-34.4-10.8-32.6-36.8c1.1-16.2,15-22.2,30.6-21.2c8.3,0.6,18.9,3.2,24.7,9.4l-7,13.5c-3.5-3.4-11.9-6.1-16.7-6.4
// 		c-4.6-0.3-11.9,0.9-12.3,6.4c-0.8,12.1,35.4,9.2,33.7,34.6C985.6,122.3,970.4,132.2,953.5,131.1z"/>
//         </g>
//       </svg>
//     </div>
//   );
// };

// export default Logo;
