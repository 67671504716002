/* eslint-disable react/prop-types */
import React from 'react';
import {
  Routes, Route, Navigate, useParams
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import YearList from '../Components/YearList';
import YearDetails from '../Components/YearDetails';


const AppRouter = () => {


  return (
    <Routes>
        <Route exact path="/" element={<YearList />} />
        <Route exact path="/yeardetails/:year" element={<YearDetails />} />

    </Routes>
  );
};

export default AppRouter;
