import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchYear } from '../Redux/yearSlice';
import '../Styles/YearList.css';
import Logo from './Logo';
import Click from './Click';

const YearList = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [selectedYear, setSelectedYear] = useState(null);
  const scrollingContainerRef = useRef(null);

  const decades = Array.from({ length: 21 }, (_, index) => 1920 + index * 10);


  useEffect(() => {
    // Set the initial scroll position to center the 1940s and 1950s
    if (scrollingContainerRef.current) {
      const initialScrollPosition = decades.indexOf(2020) * 157; // Adjust the width of a card as needed
      scrollingContainerRef.current.scrollLeft = initialScrollPosition;
    }
  }, [decades]);

  useEffect(() => {
    localStorage.removeItem('selectedYear');
  }, []);

  // const handleYearClick = (year) => {
  //   setSelectedYear(year)
  //   .then(() => {
  //     // Dispatch fetchYear after addLike is completed
  //     dispatch(fetchYear(selectedYear));
  //   localStorage.setItem('selectedYear', year)

  //   })
  //   .catch((error) => {
  //     // Handle any errors that occurred during addLike or fetchYear
  //     console.error('Error liking the year:', error);
  //   });
  //   navigate(`/yeardetails/${year}`);
  // };

  // const handleYearClick = (year) => {
  //   // Update the state
  //   setSelectedYear(year);
    
  //   // Use useEffect to dispatch the action after the state has been updated
  //   useEffect(() => {
  //     try {
  //       // Set selectedYear in local storage
  //       localStorage.setItem('selectedYear', year);
  
  //       // Dispatch fetchYear after setting the item in local storage
  //       dispatch(fetchYear(year));
  
  //       // Navigate to the desired route
  //       navigate(`/yeardetails/${year}`);
  //     } catch (error) {
  //       // Handle any errors that occurred during the process
  //       console.error('Error handling the year click:', error);
  //     }
  //   }, [year]);
  // };

  const handleYearClick = (year) => {
    setSelectedYear(year);
  
    try {
      // Set selectedYear in local storage
      localStorage.setItem('selectedYear', year);
  
      // Dispatch fetchYear after setting the item in local storage
      dispatch(fetchYear(year));
  
      // Navigate to the desired route
      navigate(`/yeardetails/${year}`);
    } catch (error) {
      // Handle any errors that occurred during the process
      console.error('Error handling the year click:', error);
    }
  };
  

  return (
    <div className="container-fluid">
      {/* <h1 className="mt-5">Review the Year!</h1> */}
      
      <h1><Logo /></h1>
      <h2 className="subtitle">and yes... the years go till 2124 'cause who knows where whom has been?</h2>
      <Click />
      <div className="scrolling-wrapper" ref={scrollingContainerRef}>
        {decades.map((decade, index) => (
          <div key={index} className="card">
            <div className="decade-label">{decade}s</div>
            <div className="years-in-decade">
              {Array.from({ length: 10 }, (_, i) => (
                <div key={i} 
                className="year"
                onClick={() => handleYearClick(decade + i)}>
                  {decade + i}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default YearList;
