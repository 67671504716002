import './App.css';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import AppRouter from './Routes/Router';


const App = () => (
  <div className="app-container">
    {/* <BrowserRouter> */}
      <section className="main-content">
        <AppRouter />
      </section>
    {/* </BrowserRouter> */}
  </div>
);


export default App;
library.add(fab, fas, far)
