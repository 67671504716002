import { configureStore } from '@reduxjs/toolkit';
import yearSlice from './yearSlice';
import reviewSlice from './reviewSlice';
// import { persistStore } from 'redux-persist';
// import persistConfig from './persistConfig';

const store = configureStore({
  reducer: {
    year: yearSlice,
    review: reviewSlice
  },
});

// const persistor = persistStore(store);

export default store;

// export { store, persistor };
