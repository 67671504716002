import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import persistConfig from './persistConfig';
// import { persistReducer } from 'redux-persist'; 
import { original } from 'immer'


export const fetchYear = createAsyncThunk('year/fetchYear', async (year) => {

  const response = await fetch(`https://reviewtheyear-f7ad7c368bd2.herokuapp.com/api/v1/years/${year}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
});

export const addLike = createAsyncThunk('year/addLike', async (year) => {

    const response = await fetch(`https://reviewtheyear-f7ad7c368bd2.herokuapp.com/api/v1/years/${year}/thumbs_up`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    return data;
  });

  export const addDislike = createAsyncThunk('year/addDislike', async (year) => {

    const response = await fetch(`https://reviewtheyear-f7ad7c368bd2.herokuapp.com/api/v1/years/${year}/thumbs_down`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    return data;
  });


const yearSlice = createSlice({
  name: 'year',
  initialState: {
    loading: false,
    year: [],
    message: '',
    isLoading: false,
    success: false,
    response: null,
    error: '',
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchYear.pending, (state) => ({
        ...state,
        loading: true,
        error: '',
      }))
      .addCase(fetchYear.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        year: action.payload,
      }))
      .addCase(addLike.pending, (state) => ({
        ...state,
        loading: true,
        error: '',
      }))
      .addCase(addLike.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        message: action.payload,
      }))
      .addCase(addDislike.pending, (state) => ({
        ...state,
        loading: true,
        error: '',
      }))
      .addCase(addDislike.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        message: action.payload,
      }))
      
  }
  })

export default yearSlice.reducer;
// export default persistReducer(persistConfig, yearSlice.reducer);