import React from 'react';
import '../Styles/Loading.css';

const Loading = () => {
    return (
        <div className="loading-container">
            <div className="yellow"></div>
            <div className="red"></div>
            <div className="blue"></div>
            <div className="violet"></div>
        </div>

    );
};

export default Loading;
