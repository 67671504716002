import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addLike, addDislike, fetchYear } from '../Redux/yearSlice';
import { addReview } from '../Redux/reviewSlice';
import Confetti from 'react-confetti-boom';
import DislikeAnimation from './DislikeAnimation';
import { Icon } from "@iconify/react";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import '../Styles/YearDetails.css';
import Back from '../Assets/left-arrow.png';
import Loading from './Loading';

const YearDetails = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [postContent, setPostContent] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const yearData = useSelector((state) => state.year.year);
    const year = yearData?.year;
    const reviews = yearData?.reviews || [];
    const selectedYear = localStorage.getItem('selectedYear');
    //   const likes = useSelector((state) => state.year.year.thumbs_up);
    const [showConfetti, setShowConfetti] = useState(false);
    const [showDislikeAnimation, setShowDislikeAnimation] = useState(false);
    const [hasLiked, setHasLiked] = useState(false);
    const [hasDisliked, setHasDisliked] = useState(false);
    //   const liked = localStorage.getItem(`liked_${selectedYear}`)


    useEffect(() => {
        dispatch(fetchYear(selectedYear));
    }, [dispatch, selectedYear]);

    // useEffect(() => {
    //       dispatch(fetchYear(year.year));
    //   }, [dispatch, year.year]);

    useEffect(() => {
        if (year) {
            setHasLiked(year.hasLiked);
            setHasDisliked(year.hasDisliked);
        }
    }, [year]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const review = { content: postContent, year_id: year?.id };
        if (postContent.trim() !== '' && year) {
            dispatch(addReview({ review }));
            setPostContent('');
            setIsOpen(false);
            window.location.reload();
        }
    };

    const handleBack = () => {
        navigate('/');
        window.location.reload();
    }

    //   const handleLike = () => {
    //     if (year) {
    //       dispatch(addLike(year.year));
    //       dispatch(fetchYear(year.year));
    //       setShowConfetti(true);
    //       setHasLiked(true);
    //       setTimeout(() => {
    //         setShowConfetti(false);
    //       }, 2000);
    //     }
    //   };
    const handleLike = () => {
        const liked = localStorage.getItem(`liked_${selectedYear}`);

        if (liked !== 'true') {
            // Run the logic for liking the year
            // dispatch(addLike(year.year));
            // dispatch(fetchYear(year.year));
            dispatch(addLike(year.year))
                .then(() => {
                    // Dispatch fetchYear after addLike is completed
                    dispatch(fetchYear(year.year));
                })
                .catch((error) => {
                    // Handle any errors that occurred during addLike or fetchYear
                    console.error('Error liking the year:', error);
                });
            setShowConfetti(true);
            setTimeout(() => {
                setShowConfetti(false);
            }, 2000);

            // Set liked in local storage to true
            localStorage.setItem(`liked_${selectedYear}`, 'true');
        } else {
            // Display a message since the year is already liked
            toast.info('You already liked this year', {
                position: toast.POSITION.TOP_CENTER,
            });
        }
    };

    // const handleLiked = () => {
    //     if (liked === true) {
    //     return('You already liked this year')
    //     }
    // };

    //   const handleDislike = () => {
    //     if (year) {
    //       dispatch(addDislike(year.year));
    //       dispatch(fetchYear(year.year));
    //       setShowDislikeAnimation(true);
    //       setHasDisliked(true);
    //       setTimeout(() => {
    //         setShowDislikeAnimation(false);
    //       }, 2000);
    //     }
    //   };

    const handleDislike = () => {
        const disliked = localStorage.getItem(`disliked_${selectedYear}`);

        if (disliked !== 'true') {
            // Run the logic for liking the year
            // dispatch(addDislike(year.year));
            // dispatch(fetchYear(year.year));
            dispatch(addDislike(year.year))
                .then(() => {
                    // Dispatch fetchYear after addLike is completed
                    dispatch(fetchYear(year.year));
                })
                .catch((error) => {
                    // Handle any errors that occurred during addLike or fetchYear
                    console.error('Error liking the year:', error);
                });
            setShowDislikeAnimation(true);
            setTimeout(() => {
                setShowDislikeAnimation(false);
            }, 2000);

            // Set liked in local storage to true
            localStorage.setItem(`disliked_${selectedYear}`, 'true');
            // window.location.reload();

        } else {
            // Display a message since the year is already liked
            // console.log('You already liked this year');
            toast.info('You already hated this year', {
                position: toast.POSITION.TOP_CENTER,
            });
        }
    };

    if (
        ["1920", "1921", "1922", "1923", "2125", "2126", "2127", "2128", "2129"].includes(
            selectedYear
        )
    ) {
        // Display a message or take any specific action for restricted years
        return (
            <div className="year-details-container">
                <p>Cannot review year {selectedYear} yet</p>
            </div>
        );
    }

    return (
        <div className={`year-details-container ${showDislikeAnimation ? 'black-background' : ''}`}>
            {showDislikeAnimation ? (
                <div className="dislike-animation-container">
                    <DislikeAnimation />
                </div>
            ) : showConfetti ? (
                <div>
                    <Confetti />
                    <h2 className='confetti-text'>You loved {year.year}!</h2>
                </div>
            ) : year ? (
                <div className='year-details-container' >
                    <div className='back-button'>
                        <img src={Back}
                            alt="back-button"
                            className="back"
                            onClick={handleBack} />
                        <p>Back</p>
                    </div>
                    <h1 className="selected-year">{year.year}</h1>
                    <ToastContainer autoClose={1000} limit={1} closeButton={false} />
                    <div className="thumbs-container">
                        <Icon
                            icon="tabler:thumb-up"
                            width="30"
                            height="30"
                            className={`thumbs-icon ${hasLiked ? 'disabled' : ''}`}
                            onClick={handleLike}
                        // disabled={hasLiked || hasDisliked}
                        />
                        <p>{year.thumbs_up}</p>
                        <Icon
                            icon="tabler:thumb-down"
                            width="30"
                            height="30"
                            className={`thumbs-icon ${hasDisliked ? 'disabled' : ''}`}
                            onClick={handleDislike}
                        // disabled={hasLiked || hasDisliked}
                        />
                        <p>{year.thumbs_down}</p>
                    </div>
                    <div className="create-post">
                        <button onClick={() => setIsOpen(!isOpen)}>Leave a Review</button>
                        {isOpen && (
                            <form onSubmit={handleSubmit}>
                                <textarea
                                    rows="4"
                                    placeholder="What was it with this year?"
                                    value={postContent}
                                    onChange={(e) => setPostContent(e.target.value)}
                                ></textarea>
                                <button type="submit">Post</button>
                            </form>
                        )}
                    </div>
                    <div className="reviews-container">
                        {reviews.length > 0 ? (
                            reviews.map((review) => (
                                <div key={review.id} className="review">
                                    <p>{review.content}</p>
                                </div>
                            ))
                        ) : (
                            <p className="review-placeholder">Be the first to leave a review for {year.year}!</p>
                        )}
                    </div>
                </div>
            ) : (
                <Loading />
            )}
        </div>
    );
};

export default YearDetails;
