import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../Styles/DislikeAnimation.scss'

const DislikeAnimation = () => {

    const yearData = useSelector((state) => state.year.year);
    const year = yearData?.year;

    return (
        <div>
            <div className="cat">
                <div className="ear ear--left"></div>
                <div className="ear ear--right"></div>
                <div className="face">
                    <div className="eye eye--left">
                        <div className="eye-pupil"></div>
                    </div>
                    <div className="eye eye--right">
                        <div className="eye-pupil"></div>
                    </div>
                    <div className="muzzle"></div>
                </div>
            </div>
            <h2 className='cat-text'>You hated {year.year}!</h2>
        </div>

    );
};

export default DislikeAnimation;
