import React from 'react';
import '../Styles/Click.css';

const Click = () => {
  return (
    <div className="container">
    <h2 className="title">
      <span className="title-word title-word-1">Tap</span>
      <span className="title-word title-word-2">on</span>
      <span className="title-word title-word-3">a</span>
      <span className="title-word title-word-4">year</span>
    </h2>
  </div>
    
  );
};

export default Click;
